import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Teams } from "./Containers/Teams";
import { UserResponse } from "./Containers/UserResponse";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Templates } from "./Containers/Templates";
import { EditTemplate } from "./Containers/EditTemplate";
import { Campaigns } from "./Containers/Campaigns";
import { EditCampaign } from "./Containers/EditCampaign";
import { Results } from "./Containers/Results";
import { PreviewTemplate } from "./Containers/PreviewTemplate";
import { OpenResponses } from "./Containers/OpenResponses";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  useQuery,
} from "@apollo/client";
import DebounceLink from "apollo-link-debounce";
import "./i18n";
import { ResultsAnon } from "./Containers/ResultsAnon";
import { ResultsPreview } from "./Containers/ResultsPreview";
import { OAuth } from "./Containers/OAuth";
import { GET_ROLE, RoleResult } from "./Queries";
import { Loading } from "./Containers/Loading";
import { UserProvider } from "./Contexts/UserContext";

Sentry.init({
  dsn: "https://076f883fd2e5469dad63c1f740605813@o4505098553524224.ingest.sentry.io/4505098592583680",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const DEFAULT_DEBOUNCE_TIMEOUT = 250;

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      (key, value) => (key === "__typename" ? undefined : value),
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const link = ApolloLink.from([
  cleanTypeName,
  new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
  createHttpLink({
    uri: "/graphql",
    credentials: "same-origin",
  }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache({
    // addTypename: false,
  }),
  link,
});

function Navigate({ to }: { to: string }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return <></>;
}

const instructorRouter = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/teams" />,
  },
  {
    path: "templates",
    element: <Templates />,
  },
  {
    path: "templates/:templateId",
    element: <EditTemplate />,
  },
  {
    path: "campaigns",
    element: <Campaigns />,
  },
  {
    path: "campaigns/:campaignId",
    element: <EditCampaign />,
  },
  {
    path: "oauth/redirect",
    element: <OAuth />,
  },
  {
    path: "results/preview/:campaignId/:userId/:source",
    element: <ResultsPreview />,
  },
  {
    path: "results/:campaignId/:subjectType?/:teamId?/:userId?",
    element: <Results />,
  },
  {
    path: "results/:campaignId/:subjectType?//:userId?",
    element: <Results />,
  },
  {
    path: "results",
    element: <ResultsAnon />,
  },
  {
    path: "preview-template/:templateId",
    element: <PreviewTemplate />,
  },
  {
    path: "response",
    element: <UserResponse />,
  },
  {
    path: "teams",
    element: <Teams />,
  },
]);

const studentRouter = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/campaigns" />,
  },
  {
    path: "campaigns",
    element: <OpenResponses />,
  },
  {
    path: "results",
    element: <ResultsAnon />,
  },
  {
    path: "response",
    element: <UserResponse />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

function App() {
  // TODO: Fix chart themes so we can restore auto dark mode
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersDarkMode = true;
  const [mode, setMode] = React.useState<"dark" | "light">();
  const { loading: roleLoading, data: roleData } = useQuery<RoleResult>(
    GET_ROLE,
    { client },
  );

  React.useEffect(() => {
    setMode(prefersDarkMode ? "dark" : "light");
  }, [prefersDarkMode]);

  let theme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: "#1c1c1c",
          },
        },
      },
    },
    palette:
      mode === "dark"
        ? {
            mode,
            primary: {
              // #4C3091
              main: "#7D5DCA",
            },
            secondary: {
              // #843193
              main: "#843193",
            },
          }
        : {
            mode,
            primary: {
              main: "#3a246f",
            },
            secondary: {
              main: "#411849",
            },
          },
  });

  theme = responsiveFontSizes(theme);
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <UserProvider>
            {roleLoading ? (
              <Loading />
            ) : (
              <RouterProvider
                router={
                  roleData?.myRole === "instructor"
                    ? instructorRouter
                    : studentRouter
                }
              />
            )}
          </UserProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
